let photoActionMixin = {
  mounted() {
    // 配置微信jssdk
    if (typeof localStorage['corpInfo'] != 'undefined') {
      console.log('配置微信jssdk')
      try {
        let corpInfo = JSON.parse(localStorage['corpInfo'])
        console.log(corpInfo)
        this.wxConfig(corpInfo)
      } catch (e) {

      }
    }
  },

}

export default photoActionMixin;