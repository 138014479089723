<template>
  <div class="deviceRepair" id="deviceRepair">
    <div class="mainContent">
      <div class="mian">
        <div class="item">
        <span class="itemLabel itemMust">设备名称</span
        ><span class="itemValue">
          <el-input
              class="inputView"
              size="mini"
              v-model="baseData.deviceName"
              placeholder="请输入设备名称"
          ></el-input>
        </span>
        </div>
        <div class="item">
          <span class="itemLabel itemMust" @click.stop="changeSnInPutMode">{{$VUEAPPDEVICECODE()}}</span>
          <span v-if="codeMode=='scan'" class="itemValue" @click.stop="showDeviceDiaLog">
          {{ baseData.code }}
          <span class="scanning" @click.stop="getscanQRCode"><img src="../../../../../static/iconImage/photo.png"/>扫描</span>
          </span>

          <span v-if="codeMode=='input'" class="itemValue">
          <el-input
              class="inputView"
              size="mini"
              v-model="baseData.tempCode"
              placeholder="请输入设备SN"
              @input="deviceModeChange"
          ></el-input>
        </span>
        </div>
        <div class="item">
          <span class="itemLabel itemMust">设备类型</span>
          <span class="itemValue">
          <el-input
              class="inputView"
              size="mini"
              v-model="baseData.modelName"
              placeholder="请输入设备类型"
          ></el-input>
        </span>
        </div>
        <div class="item">
        <span class="itemLabel itemMust">设备地址</span
        ><span class="itemValue">
          <el-input
              style="width: auto"
              class="inputView"
              size="mini"
              v-model="baseData.address"
              placeholder="请输入设备地址"
          ></el-input>
        </span>
        </div>
        <div class="item">
        <span class="itemLabel itemMust">联系电话</span
        ><span class="itemValue">
          <el-input
              oninput="value=value.replace(/[^\d]/g,'')"
              class="inputView"
              size="mini"
              v-model="baseData.phone"
              placeholder="请输入联系电话"
          ></el-input>
        </span>
        </div>
        <div class="item">
        <span class="itemLabel">报修人</span
        ><span class="itemValue">
          <el-input
              class="inputView"
              size="mini"
              v-model="baseData.name"
              placeholder="请输入联系电话"
          ></el-input>
        </span>
        </div>
      </div>
      <div class="mian">
        <div class="item">
        <span class="itemLabel">紧急度</span
        ><span class="itemValue">
          <span
              class="option"
              :class="{ optionActive: item.value == baseData.severity }"
              @click="checkSeverity(item.value)"
              v-for="item in severityOption"
              :key="item.value"
          >{{ item.label }}</span
          >
        </span>
        </div>
        <div class="item">
        <span class="itemLabel">设备状态</span
        ><span class="itemValue">
          <span
              class="option"
              :class="{ optionActive: item.value == baseData.deviceStatus }"
              @click="checkDeviceStatus(item.value)"
              v-for="item in deviceStatusOption"
              :key="item.value"
          >{{ item.label }}</span
          >
        </span>
        </div>
        <div class="item">
        <span class="itemLabel">备用机</span
        ><span class="itemValue">
          <span
              class="option"
              :class="{ optionActive: item.value == baseData.standby }"
              @click="checkStandby(item.value)"
              v-for="item in standbyOption"
              :key="item.value"
          >{{ item.label }}</span
          >
        </span>
        </div>
      </div>
      <div class="mian">
        <div class="title">故障描述</div>
        <div class="textarea">
          <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 6 }"
              placeholder="请描述故障情况"
              v-model="baseData.remark"
          >
          </el-input>
        </div>
      </div>
      <div class="mian">
        <div class="title">故障拍照 <span style="font-size: 10px;color: #aaaaaa">最多上传3张照片</span></div>
        <div class="imageList">
          <div class="imageItem" v-for="(item, index) in imageList" :key="item.orderId">
          <span class="viewerClose" @click="delImg(item.id)"
          ><i class="el-icon-close"></i
          ></span>
            <img
                :src="$replacePhoto(item.image)"
                class="userImg"
                @click="showImageList(index)"
            />
          </div>
          <div class="upload">
            <img
                :src="addImage"
                class="addImage"
                alt=""
                @click="getchooseImage"
            />
          </div>
        </div>
      </div>
    </div>


    <div class="deviceFooter" @click="showDialog">提交</div>
    <select-device-dialog @selectDevice="selectDevice" :table-data="tableData"
                          ref="selectDevice"
    ></select-device-dialog>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import severityOption from '@/mixins/severityOption/index.js'
import deviceStatusOption from '@/mixins/deviceStatusOption/index.js'
import photoActionMixin from '@components/photoActionMixin/photoActionMixin'
import SelectDeviceDialog from '@views/shareAndDispatch/deviceBorrow/deviceFailure/selectDeviceDialog'

export default {
  name: 'deviceRepair',
  mixins: [severityOption, deviceStatusOption, photoActionMixin],
  components: {
    SelectDeviceDialog,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      timeOut:null,
      codeModeCount: 0,
      codeMode: 'scan',
      modeTimeOut: null,
      tableData: [],
      loading: false,
      loadingView: null,
      rate: 5,
      baseData: {
        remark: '',
        phone: '',
        name: '',
        id: '',
        severity: 1,
        deviceStatus: 0,
        standby: 0,
        code: '',
        tempCode:'',
        textarea: '',
        address: '',
        modelName: '',
        deviceName: ''
      },
      standbyOption: [
        {
          label: '有',
          value: 1
        },
        {
          label: '无',
          value: 0
        }
      ],
      photo: require('../../../../../static/iconImage/photo.png'),
      addImage: require('@/icons/imgs/addImage.png'),
      imageList: [],
      msg: '',
      imageId: '',
      leaturesList: []
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading('deviceRepair')
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      }
    }
  },
  created() {
    let query = this.$route.query
    let userInfo = this.$store.getters.userInfo
    this.baseData.id = query.id
    this.baseData.code = query.code || ''
    this.baseData.modelName = query.modelName || ''
    this.baseData.deviceName = query.name || ''
    this.baseData.phone = userInfo.phone || ''
    this.baseData.name = userInfo.name || ''
    this.baseData.tempCode = this.baseData.code;
  },
  mounted() {
    this.$nextTick(() => {
      // 配置微信jssdk
      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.wxConfig(corpInfo)
        } catch (e) {
        }
      }
    })
    this.sharFeatures()
  },
  methods: {
    deviceModeChange:function() {
      if (this.timeOut != null) {
        clearTimeout(this.timeOut)
      }

      if (this.baseData.tempCode == this.baseData.code) {
        return;
      }

      this.timeOut = setTimeout(() => {
        this.baseData.code = this.baseData.tempCode;
        this.findDeviceList();
      }, 1000)
    },
    changeSnInPutMode: function() {
      if (this.modeTimeOut != null) {
        clearTimeout(this.modeTimeOut)
      }

      this.codeModeCount = this.codeModeCount + 1
      if (this.codeModeCount >= 3) {
        this.codeMode = (this.codeMode == 'scan' ? 'input' : 'scan')
        this.codeModeCount = 0
        return
      }

      this.modeTimeOut = setTimeout(() => {
        this.codeModeCount = 0
      }, 1000)

    },
    showDeviceDiaLog: function() {
      this.$refs['selectDevice'].showOrHidden(true)
    },
    selectDevice: function(res) {
      for (const resKey in res) {
        this.baseData[resKey] = res[resKey]
      }
      this.baseData.tempCode = this.baseData.code;
      this.sharFeatures()
    },
    sharFeatures() {
      if (this.$valueIsExist(this.baseData, 'id') == false || this.baseData.id == '') {
        return
      }
      this.$api.deviceBorrowModule
          .sharFeatures({
            deviceId: this.baseData.id
          })
          .then((res) => {
            this.leaturesList = res.data
          })
          .catch((res) => {
          })
    },
    getscanQRCode() {
      this.scanQRCode().then((res) => {
        if (res.result == false) {
          this.loading = false
          this.$toast.fail('请扫描二维码或者条形码')
          return
        }
        this.startUsing(res)
      })
    },
    startUsing(res) {
      this.baseData.code = res.res
      this.baseData.tempCode = this.baseData.code;
      this.findDeviceList()
    },
    findDeviceList: function() {
      let getData = () => {
        return new Promise(resolve => {
          let postInfo = {
            deleted: 0,
            query: this.baseData.code,
            page: 1,
            pageSize: 1000,
            order: '',
            orderType: '',
            tenantId: '',
            isTenantIdChild: 1,
            categoryId: '',
            isCategoryIdChild: 1,
            status: '',
            type: '',
            industryId: '',
            checkStatus: '',
            deviceInventoryStatus: ''
          }

          this.$api.assetModule.getSearchDeviceList(postInfo).then(res => {
            let list = []
            if (this.$valueIsExist(res, 'data')) {
              list = res['data']
            }
            resolve({ list: list })
          }).catch(e => {
            resolve({ list: [] })
          })
        })
      }

      this.tableData = []
      this.loading = true
      getData().then(res => {
        this.tableData = res.list
        this.loading = false
        this.showDeviceDiaLog()
      })

    },
    cancelPhoto: function(index) {
      this.imageList.splice(index, 1)
    },
    getchooseImage() {
      this.chooseImage().then((res) => {
        if (res.result == false) {
          this.imageId = ''
          this.$toast.fail('请重新选择图片')
          return
        }
        this.imageId = res.localID
        this.saveImageToServer()
      })
    },
    saveImageToServer() {
      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.loading = true
          this.uploadImageToWeChatAndGet(this.imageId, corpInfo)
              .then((res) => {
                if (res.result == false) {
                  this.$toast.fail('获取图片失败')
                  this.loading = false
                  return
                }
                this.imageList.push({
                  id: res.image.id,
                  image: res.image.url
                })
                console.log(this.imageList)
                this.loading = false
              })
              .catch((err) => {
                this.loading = false
              })
        } catch (e) {
          this.$toast.fail('解析图片失败')
          this.loading = false
        }
      }
    },
    showDialog() {
      if (
          !this.baseData.phone ||
          !this.baseData.code ||
          !this.baseData.modelName ||
          !this.baseData.address
      ) {
        this.$toast.fail('请完善信息后提交')
        return
      }
      this.$dialog.confirm({
        message: `确认设备报障？`,
        beforeClose: this.shareReport
      })
    },
    checkSeverity(e) {
      this.baseData.severity = e
    },
    checkStandby(e) {
      this.baseData.standby = e
    },
    checkDeviceStatus(e) {
      this.baseData.deviceStatus = e
    },
    shareReport(action, done) {
      let request = () => {
        let viewType = 'borrow'
        let query = this.$route.query
        if (this.$valueIsExist(query, 'viewType')) {
          viewType = query['viewType']
        }
        let _photo = []
        this.imageList.forEach((element) => {
          _photo.push(element.id)
        })
        if (viewType == 'borrow') {
          return this.$api.deviceBorrowModule
              .shareReport({
                id: this.baseData.id,
                severity: this.baseData.severity,
                address: this.baseData.address,
                phone: this.baseData.phone,
                remark: this.baseData.remark,
                deviceStatus: this.baseData.deviceStatus,
                standby: this.baseData.standby,
                photo: _photo.join()
              })
        }

        return this.$api.deviceBorrowModule
            .deviceReport({
              id: this.baseData.id,
              severity: this.baseData.severity,
              address: this.baseData.address,
              phone: this.baseData.phone,
              remark: this.baseData.remark,
              deviceStatus: this.baseData.deviceStatus,
              standby: this.baseData.standby,
              photo: _photo.join()
            })
      }

      if (action != 'confirm') {
        done()
        return
      }
      request().then((res) => {
        localStorage.setItem('allDeviceUpdate', new Date().getTime())
        this.$toast.success('操作成功')
        done()
        this.$back()
      }).catch((e) => {
        this.$toast.fail(e.data.msg)
        done()
      })
    },
    showImageList: function(index) {
      let list = []
      for (let i = 0; i < this.imageList.length; i++) {
        let data = this.imageList[i]
        list.push(this.$replacePhoto(data.image))
      }
      ImagePreview(list, index)
    },
    delImg(id) {
      this.imageList = this.imageList.filter((item) => {
        return item.id != id
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceRepair {
  height: 100%;
  overflow: scroll;
  padding: 10px;

  .mainContent {
    height: calc(100% - 50px);
    overflow: scroll;

    .mian {
      background: $background_color2;
      padding: 12px;
      border-radius: 2px;
      margin-bottom: 10px;

      .item {
        font-size: 14px;
        height: 38px;
        line-height: 37px;

        .itemLabel {
          color: $color3;
        }

        .itemMust::after {
          content: "*";
          color: #ff3a3a;
          margin-right: 4px;
          font-size: 16px;
        }

        .itemValue {
          float: right;

          .inputView {
            width: auto;
            float: left;
            font-size: 14px;
          }

          .scanning {
            color: #3e73fb;

            img {
              width: 16px;
              vertical-align: sub;
            }
          }

          .option {
            display: inline-block;
            margin-left: 8px;
            width: 66px;
            height: 24px;
            line-height: 22px;
            background: #f5f5f5;
            border: 1px solid #f5f5f5;
            border-radius: 12px;
            color: $color1;
            font-size: 14px;
            text-align: center;
          }

          .optionActive {
            border: 1px solid #3e73fb;
            color: #3e73fb;
            background: #d8e3ff;
          }
        }
      }

      .title {
        font-size: 14px;
        color: $color3;
        padding-bottom: 6px;
        color: $color1;
        font-weight: 500;
      }

      .imageList {
        display: flex;
        overflow: scroll;
        margin-top: 10px;

        .imageItem {
          margin-right: 10px;
          position: relative;

          .userImg {
            width: 104px;
            height: 104px;
            border-radius: 2px;
          }

          .viewerClose {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            line-height: 24px;
            text-align: center;
            right: 4px;
            top: 4px;
            background: rgba($color: #323233, $alpha: 0.2);
            color: #ffffff;
          }
        }

        .upload {
          .addImage {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }


  .deviceFooter {
    margin-top: 10px;
    position: static;
    color: #ffffff;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    background: #3e73fb;
  }
}

::v-deep .el-textarea__inner {
  border: 0 none;
  background: #fbfbfb;
  padding: 5px;
}

::v-deep .itemValue .el-input__inner {
  border: none;
  border-radius: 0px;
  text-align: right;
  padding: 0;
}


</style>