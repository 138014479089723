<template>
  <van-dialog @confirm="confirm" @cancel="cancel" v-model="show" title="设备选择" show-cancel-button
              :show-confirm-button="deviceId!=''"
  >
    <van-list class="selectDeviceDialogList">
      <div class="deviceListView" v-for="(item, index) in tableData"
           @click="selectDevice(item)"
      >
        <div class="selectView">
          <van-radio-group v-model="deviceId">
            <van-radio :name="item.id"></van-radio>
          </van-radio-group>
        </div>
        <div class="contentView">
          <div class="content">
            <div class="title">设备名称</div>
            <div class="value">{{ item.name }}</div>
            <div style="clear: both"></div>
          </div>
          <div class="content">
            <div class="title">{{$VUEAPPDEVICECODE()}}</div>
            <div class="value">{{ item.code }}</div>
            <div style="clear: both"></div>
          </div>
          <div class="content">
            <div class="title">设备卡片号</div>
            <div class="value">{{ item.label }}</div>
            <div style="clear: both"></div>
          </div>
        </div>

      </div>
    </van-list>
  </van-dialog>
</template>

<script>
export default {
  name: 'selectDeviceDialog',
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    deviceId() {
      return this.$getDataValue(this.selectItem, 'id', '')
    }
  },
  data() {
    return {
      show: false,
      selectItem: null,
      copySelectItem:null,
    }
  },
  methods: {
    showOrHidden: function(show) {
      this.show = show
    },
    cancel: function() {
      this.selectItem = this.copySelectItem;
      this.show = false
    },
    confirm: function() {
      if (this.selectItem == null) {
        return
      }
      this.copySelectItem = JSON.parse(JSON.stringify(this.selectItem))
      this.$emit('selectDevice', this.selectItem)
      this.show = false
    },
    selectDevice: function(item) {
      let code = this.$getDataValue(item, 'code', '')
      let name = this.$getDataValue(item, 'name', '')
      let categoryName = this.$getDataValue(item, 'categoryName', '')
      let place = this.$getDataValue(item, 'place', '')
      let id = this.$getDataValue(item, 'id', '')

      let dataInfo = {
        name: name,
        id: id,
        code: code,
        address: place,
        modelName: categoryName,
        deviceName: name
      }
      this.selectItem = dataInfo
    }
  }
}
</script>

<style lang="scss" scoped>

.selectDeviceDialogList {
  max-height: 300px;
  padding: 10px;
  overflow: scroll;

  .select {
    background-color: #a0cfff;
  }

  .deviceListView {
    border-bottom: 1px solid #aaaaaa;
    display: flex;
    justify-content: center;
    align-items: center;

    .selectView {
      width: 32px;
      height: 32px;
    }

    .contentView {
      width: calc(100% - 35px);
      padding: 0px 10px 0px 10px;

      .content {
        width: 100%;
        margin-bottom: 5px;

        .detailTitle {
          width: 100%;
          line-height: 20px;
          font-size: 13px;
          word-wrap: break-word;
          word-break: break-all;
        }


        .title {
          width: 100px;
          height: 20px;
          line-height: 20px;
          font-size: 13px;
          float: left;
        }

        .value {
          width: calc(100% - 110px);
          line-height: 20px;
          font-size: 13px;
          text-align: right;
          float: right;
          word-wrap: break-word;
          word-break: break-all;
        }

      }
    }

  }

}

</style>